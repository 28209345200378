import { Grid, Hidden } from "@mui/material"

import Review from "./Review"
import styled from "@emotion/styled"

export default function Reviews({ reviewLeft, reviewRight }) {
  return (
    <ReviewWrapper container className="reveal">
      <ReviewBody item md={12} lg={6}>
        <Review review={reviewLeft} />
      </ReviewBody>

      <Hidden lgDown>
        <ReviewBody item md={12} lg={6}>
          <Review review={reviewRight} />
        </ReviewBody>
      </Hidden>
    </ReviewWrapper>
  )
}

const ReviewWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const ReviewBody = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
`
