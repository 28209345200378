import styled from "styled-components"

export default function GroupPhoto({ source, alt }) {
  return (
    <ImageWrapper>
      <img src={source} alt={alt} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  img {
    max-width: 100%;
    border-radius: 0.2rem;
    box-shadow: 0 0 0 0.2rem white;
  }
`
