/* eslint-disable no-unused-vars */
import {
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs"
import { Grid, Hidden } from "@mui/material"

import ContactForm from "./ContactForm"
import { base } from "../constants"
import styled from "@emotion/styled"

export default function Footer({ isEnglish }) {
  return (
    <FooterWrapper container>
      <LeftColumn item xs={12} md={6}>
        <SubTitle>{isEnglish ? "Questions?" : "Vragen?"}</SubTitle>

        <ContactForm isEnglish={isEnglish} />
      </LeftColumn>

      <RightColumn item xs={12} md={6}>
        <InfoWrapper>
          <SocialMediaWrapper container>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <a
                href="https://showbird.com/nl/search?artist=2215&amp;ref=button"
                title="Beoordeling op ShowBird 5 sterren d"
                target="_new"
                style={{ zoom: 1.1 }}>
                <Image
                  src="https://showbird.com/images/embed_images/review5stars_black1.png"
                  alt="Boek ons op Showbird"
                />

                <p>
                  <b>
                    {isEnglish ? "Book us on Showbird" : "Boek ons op Showbird"}
                  </b>
                </p>
              </a>
            </Grid>

            <Hidden only={["sm", "lg"]}>
              <StyledHr />
            </Hidden>

            <Grid item xs={12} sm={6} md={12} lg={6}>
              <ContactWrapper>
                <LinkWrapper
                  href="https://wa.me/message/WTY3OGWQ6WHZJ1"
                  target="_new"
                  alt="Bereikbaar via Whatsapp">
                  <BsWhatsapp size={25} color={base} />

                  <LinkText>
                    {isEnglish
                      ? "Contact us on Whatsapp"
                      : "Bereikbaar via Whatsapp"}
                  </LinkText>
                </LinkWrapper>
              </ContactWrapper>

              <ContactWrapper>
                <LinkWrapper
                  href="tel:+31641763563"
                  target="_new"
                  alt="Telefoonnummer">
                  <BsFillTelephoneFill size={25} color={base} />

                  <LinkText>+31 (0)6 417 63 563</LinkText>
                </LinkWrapper>
              </ContactWrapper>

              <ContactWrapper>
                <LinkWrapper
                  href="mailto:info@stepthegap.com"
                  target="_new"
                  alt="Email">
                  <BsFillEnvelopeFill size={25} color={base} />

                  <LinkText>info@stepthegap.com</LinkText>
                </LinkWrapper>
              </ContactWrapper>
            </Grid>
          </SocialMediaWrapper>

          <StyledHr />

          <p>
            <b>Step the Gap</b>
            <br />
            <b>Steven de Lange</b>
            <br />
            <br />
            Jutfaseweg 243
            <br />
            3522HS Utrecht
            <br />
            <br />
            <b>KvK:</b> 62690523
            <br />
            <b>Btw:</b> NL002189580B82
            <br />
            <b>Bank:</b> NL51 BUNQ 2034 6771 61
          </p>
        </InfoWrapper>
      </RightColumn>
    </FooterWrapper>
  )
}

const FooterWrapper = styled(Grid)`
  width: 100%;
  min-height: 20rem;
  text-align: center;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    160deg,
    rgba(0, 0, 0, 1) 54%,
    rgba(75, 46, 110, 1) 82%
  );

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding: 2rem;

  @media only screen and (max-width: 599px) {
    padding: 2rem 0;
  }

  @media only screen and (max-width: 899px) {
    gap: 2rem;
  }
`

const LeftColumn = styled(Grid)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const RightColumn = styled(Grid)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InfoWrapper = styled.div`
  width: 90%;
  min-height: 20rem;
  border-radius: 0.5rem;
  padding: 0.3rem;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  box-shadow: 0.1rem 0.1rem 0.3rem black;
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LinkWrapper = styled.a`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const LinkText = styled.h3`
  font-weight: bold;
  padding-left: 0.5rem;
  font-size: 1rem;
`

const Image = styled.img`
  margin-top: 1.2rem;
  width: 180px;
  border-radius: 0.3rem;
  box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.5);
`

const SocialMediaWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5rem;
`

const StyledHr = styled.hr`
  width: 70%;
  color: var(--tertiary);
`

export const SubTitle = styled.h2`
  width: 100%;
  font-weight: bolder;
  margin: 0;
  font-family: "Questrial", sans-serif;
`
