import {
  BsFacebook,
  BsGlobe2,
  BsInstagram,
  BsWhatsapp,
  BsYoutube,
} from "react-icons/bs"

import DropDownMenu from "./DropDownMenu"
import styled from "styled-components"

export default function NavBar({ isEnglish, setEnglish }) {
  return (
    <NavBarWrapper>
      <ButtonWrapper>
        <SocialMediaButton
          href="https://www.youtube.com/user/stepthegap"
          target="_blank">
          <BsYoutube size={30} />
        </SocialMediaButton>

        <SocialMediaButton
          href="https://www.facebook.com/stepthegap"
          target="_blank">
          <BsFacebook size={30} />
        </SocialMediaButton>

        <SocialMediaButton
          href="https://www.instagram.com/stepthegap/?hl=en"
          target="_blank">
          <BsInstagram size={30} />
        </SocialMediaButton>

        <SocialMediaButton
          href="https://wa.me/message/WTY3OGWQ6WHZJ1"
          target="_blank">
          <BsWhatsapp size={30} />
        </SocialMediaButton>
      </ButtonWrapper>

      <ButtonWrapper>
        <SocialMediaButton onClick={() => setEnglish(!isEnglish)}>
          <BsGlobe2 size={30} />
        </SocialMediaButton>

        <DropDownMenu />
      </ButtonWrapper>
    </NavBarWrapper>
  )
}

const NavBarWrapper = styled.div`
  z-index: 1;
  position: fixed;
  min-height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.5rem;
`

const SocialMediaButton = styled.a`
  background-color: var(--primary);
  padding: 0.5em;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.3rem black;
`
