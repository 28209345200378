import styled from "@emotion/styled"

export default function Review({ review }) {
  return (
    <ReviewCard>
      <ReviewHeader>
        <ReviewDate>{review.date}</ReviewDate>

        <ReviewRating>
          {blueStar}
          {blueStar}
          {blueStar}
          {blueStar}
          {blueStar}
        </ReviewRating>
      </ReviewHeader>

      <ReviewBody>
        <ReviewTitle>{review.title}</ReviewTitle>

        <ReviewText>{review.text}</ReviewText>

        <ReviewName>- {review.name}</ReviewName>
      </ReviewBody>
    </ReviewCard>
  )
}

const ReviewCard = styled.div`
  width: 100%;
  min-height: 10rem;
  padding: 1rem;
  border-radius: 0.3rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;

  background-color: white;
`

const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const ReviewDate = styled.div`
  color: darkgray;
  font-size: 18px;
`

const ReviewRating = styled.div`
  width: 10rem;

  display: flex;
  justify-content: center;

  svg {
    max-height: 1.7rem;
  }
`

const ReviewBody = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-family: "Questrial", sans-serif;
  color: black;
  font-size: 18px;
`

const ReviewTitle = styled.h3`
  margin: 0;
  text-align: left;
`

const ReviewText = styled.div`
  text-align: left;
`

const ReviewName = styled.div`
  justify-self: flex-end;
  text-align: right;
`

const blueStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100"
    height="100"
    fill="rebeccapurple">
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.86L12 17.77l-6.18 3.23L7 14.14l-5-4.87 6.91-1.01L12 2z" />
  </svg>
)
