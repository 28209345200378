import { Box, Grid, Hidden } from "@mui/material"

import Footer from "./components/Footer"
import GroupPhoto from "./components/GroupPhoto"
import { InstagramEmbed } from "react-social-media-embed"
import IntroText from "./components/IntroText"
import NavBar from "./components/NavBar"
import PromoVideo from "./components/PromoVideo"
import Reviews from "./components/Reviews"
import Spotify from "./components/Spotify"
import styled from "@emotion/styled"
import { useState } from "react"

const stepTheGapLogoCropped = require("./source/Step the Gap - Website background.jpg")

// const stepTheGapXL = require('./source/group photos/StG XL 1.jpg');
// const stepTheGapXLLive = require('./source/group photos/StG XL 2.jpg');
const stepTheGapWeb1 = require("./source/group photos/Step the Gap - Web 1.jpg")
const stepTheGapWeb2 = require("./source/group photos/Step the Gap - Web 2.jpg")

function reveal() {
  var reveals = document.querySelectorAll(".reveal")

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight
    var elementTop = reveals[i].getBoundingClientRect().top
    var elementVisible = 150

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active")
    } else {
      reveals[i].classList.remove("active")
    }
  }
}

window.addEventListener("scroll", reveal)

function App() {
  const [isEnglish, setEnglish] = useState(false)

  const review1 = {
    date: "April 2022",
    title: isEnglish ? "Musical top performers" : "Muzikale toppers",
    text: isEnglish
      ? "Step the Gap is definitely recommended. Incredibly nice people who bring great enthusiasm and a wonderful sound. They were extremely flexible with our somewhat crazy requests on a very important day for us, and they got the crowd dancing with ease. High praise to both band members and the men of sound engineering!"
      : "Step the Gap is absoluut aan te raden. Ontzettend aardige mensen die veel enthousiasme en een prachtig geluid meebrengen. Ze waren uitermate flexibel met onze ietwat gekke verzoeken op een voor ons heel belangrijke dag en ze kregen het publiek met gemak aan het dansen. Veel lof voor zowel bandleden als de mannen van de techniek!",
    name: "Sebastian",
  }
  const review2 = {
    date: isEnglish ? "August 2021" : "Augustus 2021",
    title: "Signed, Sealed, Delivered",
    text: isEnglish
      ? "The wedding couple and guests enjoyed a wonderful set full of classics! As liaison, I had very nice contact with Step the Gap before and after the show. On the day itself, they were super flexible despite the wedding timetable being slightly off. It was no problem at all. Thank you so much!"
      : "Het bruidspaar en de gasten hebben genoten van een heerlijke set vol klassiekers! Ik heb als liaison heel fijn contact gehad met Step the Gap voor én na de show. Op de dag zelf waren ze super flexibel ondanks dat de timetable van de bruiloft iets uitliep. Het was allemaal geen enkel probleem. Heel erg bedankt!",
    name: "Romy",
  }
  const review3 = {
    date: "September 2019",
    title: isEnglish
      ? "Step the Gap is a high quality, professional cover band"
      : "Step the Gap is een hele goede, professionele coverband",
    text: isEnglish
      ? "What an insanely good band! Step the Gap XL made our wedding party groove from beginning to end. The dance floor was packed and our guests were wildly enthusiastic. Thanks to Step the Gap we had a super dance party."
      : "Wat een waanzinnig goede band! Step the Gap XL heeft ons huwelijksfeest van het begin tot het einde laten knallen. De dansvloer was overvol en onze gasten laaiend enthousiast. Dankzij Step the Gap hebben we een super dansfeest gehad.",
    name: "Victor",
  }
  const review4 = {
    date: isEnglish ? "January 2024" : "Januari 2024",
    title: isEnglish ? "Book now—let's groove!" : "Twijfel niet, boek gewoon!",
    text: isEnglish
      ? "Step the Gap perfectly matched the theme of our Christmas party: Disco Inferno. Brimming with enthusiasm, they performed for an audience of 350, setting the tone from the very first moment. A solid mix of songs made its way into the show. The cries of “We want more!” echoed long after. Don't hesitate, just book!"
      : 'Step the Gap sloot perfect aan op het thema van ons kerstfeest: Disco Inferno. Vol enthousiasme hebben zij opgetreden voor 350 man en vanaf de eerste seconde brachten zij de sfeer er goed in. Een mooie mix van nummers kwam langs. Het "We want more!" zoemde nog lang na. Don\'t hesitate, just book!',
    name: "Elena",
  }

  return (
    <AppWrapper>
      <div id="home" />
      <NavBar isEnglish={isEnglish} setEnglish={setEnglish} />

      <Hidden lgUp>
        <BlackBorder />
      </Hidden>

      <AppBody container>
        <IntroWrapper item sm={12} md={11} lg={11}>
          <Logo src={stepTheGapLogoCropped} alt="Step the Gap - Logo" />

          <IntroText isEnglish={isEnglish} />
        </IntroWrapper>

        <ContentWrapper item sm={11} md={10} lg={9}>
          <GroupPhoto source={stepTheGapWeb1} alt="Step the Gap XL - Live" />

          <div id="promovideo">
            <PromoVideo />
          </div>

          <Reviews reviewLeft={review2} reviewRight={review1} />

          <SocialMediaWrapper>
            <SocialMediaText>
              {isEnglish
                ? "Follow us on social media!"
                : "Volg ons op social media!"}
            </SocialMediaText>

            <InstagramEmbed
              url="https://www.instagram.com/stepthegap"
              width={"100%"}
            />
          </SocialMediaWrapper>

          <GroupPhoto source={stepTheGapWeb2} alt="Step the Gap XL" />

          <Reviews reviewLeft={review4} reviewRight={review3} />

          <div id="repertoire">
            <Spotify />
          </div>
        </ContentWrapper>
      </AppBody>

      <div id="footer" />
      <Footer isEnglish={isEnglish} />
    </AppWrapper>
  )
}

const AppWrapper = styled(Box)`
  --primary: hsl(267, 41%, 31%);
  --secondary: hsl(267, 41%, 41%);
  --tertiary: hsl(267, 41%, 21%);
  --special: hsl(39, 79%, 48%);
  --success: hsl(87, 41%, 41%);
  --warning: hsl(357, 41%, 51%);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }

  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
`

const BlackBorder = styled.div`
  min-height: 4.5rem;
`

const AppBody = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 0 2rem;

  @media only screen and (max-width: 599px) {
    padding: 0 1rem;
  }
`

const IntroWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

const ContentWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const SocialMediaWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

const SocialMediaText = styled.h1`
  margin: 0;

  font-family: "Questrial", sans-serif;
  color: var(--special);
  font-weight: 900;
`

const Logo = styled.img`
  max-width: 100%;
`

export default App
