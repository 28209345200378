import styled from "@emotion/styled"

export default function Spotify() {
  return (
    <SpotifyWrapper>
      <iframe
        title="Repertoire"
        src="https://open.spotify.com/embed/playlist/0FDSKPimQw13i0iuNmOOWL?utm_source=generato"
        width="90%"
        height="600"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    </SpotifyWrapper>
  )
}

const SpotifyWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`
